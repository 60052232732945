import React from 'react';
import Img from 'gatsby-image';
import {Grid, Paper, Typography} from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';



const ProviderProfiles = () => {

    const {Mendez,Barton} = useStaticQuery(graphql`query {
        Mendez: file(relativePath: {eq: "Mendez_A730053.JPG"}) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          Barton: file( relativePath: {eq: "Barton_A730049_Edited.JPG"}) {
            childImageSharp {
              fluid{
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
      `)
    return (
                <>
                    <div className="mendez-box">
                        <Img style={{maxWidth:500}} fluid={Mendez.childImageSharp.fluid} alt='Profile picture of Dr. Jose Mendez'/>

                        <Typography variant="h5" component='h2' m={16}>Dr. Jose Mendez DO</Typography>

                        <Typography variant="subtitle2" component='h4'>Dermatologist</Typography>
                        <Typography variant="subtitle2" component='h4'>Doctor of Osteopathic Medicine</Typography>

                        <Typography variant="body1" component='p'>Dr. Jose Mendez has over twenty years of experience as a physician practicing in a variety of settings including dermatology and dermatological surgery, wound care, critical care, primary care, and research. He has over thirteen years of experience in general dermatology and Mohs surgery. Dr. Mendez served as a voluntary Assistant Professor of Dermatology and Cutaneous Surgery at the University of Miami Miller School of Medicine. Dr. Mendez has also served as a Clinical Professor for Internal Medicine and Dermatology with the Lake Erie College of Osteopathic Medicine and Clinical Professor for the Department of Dermatology Nova Southeastern University.</Typography>
                        <Typography variant="body1" component='p'>Dr. Mendez has served as the principal investigator for several dermatology research trials. He graduated from Nova Southeastern College of Osteopathic Medicine in 1994 and is fluent in English and Spanish. Dr. Mendez is board certified in Dermatology by the American Board of Physician Specialties, is a member of the American Society for Dermatologic Surgery and specializes in Mohs surgery.  Dr Mendez is also board certified in Geriatric Care and Family Medicine.</Typography>
                        
                        <Typography variant="h5" component='h4'>Publications</Typography>
                        <Typography variant="body2" component='p'>A Clinical Case of Lyme Disease in South Florida. Syed A. A. Rizvi, PhD., Jose Mendez, DO., Sultan S. Ahmed, MD., Jasmin Ahmed, BS. Consultant: 2017; 57 (2):115-120. </Typography>
                        <Typography variant="body2" component='p'>A Clinical Case Presentation of Keratosis Folicularis. ( Darier White Disease ) Jaqueline Bullock, BS PA-S., Syed A. A. Rizvi, PhD., Jose Mendez, DO., Jasmin Ahmed., Suarez BS., Jose D. Suarez, MD., Sultan S. Ahmed, MD. Consultant: 2017; 57 (3):182-183.</Typography>
                        <br/>
                    </div>
                    <div className="barton-box">
                        <Img style={{maxWidth:500}} fluid={Barton.childImageSharp.fluid} alt='Profile picture of Dr. Jose Mendez'/>
                        <Typography variant="h5" component='h2'>Michelle Barton ARNP, DNP</Typography>
                        <Typography variant="subtitle2" component='h4'>Nurse Practitioner</Typography>
                        <Typography variant="body1" component='p'>Michelle Barton has over 20 years of dermatology experience as a Nurse Practitioner.  She was an active duty army officer assigned to the United States Military Academy and Walter Reed Army Hospital.  She obtained a Master’s degree in Nursing from Johns Hopkins University, and received the Ruth Dale Ogilvy award for exceptional academic performance in 1995. Michelle also obtained her Adult and Pediatric Nurse Practitioner certificates from Johns Hopkins University in 1996 and 1997. She is also certified as a Dermatology Nurse Clinician.</Typography>
                        <Typography variant="body1" component='p'>Michelle received her Doctorate in Nursing Practice from the University of South Florida (Nurse Practitioner Dermatology Residency Program) in 2014. Her special interests in dermatology include skin cancer surveillance and prevention and skin disorders in women, teenagers and children.  She has participated in several research studies, including acne, rosacea, and psoriasis studies.</Typography>

                        <Typography variant="h5" component='h4'>Publications</Typography>
                        <Typography variant="body2" component='p'>(2013). Adding sclerotherapy to your practice. Advance for NPs &#38; Pas e-newsletter.</Typography>
                        <Typography variant="body2" component='p'>(2012). Access to Dermatology Care in Rural Populations. The Journal for Nurse Practitioners, 8(2), 160-161.</Typography>
                        <Typography variant="body2" component='p'>(2008). Rosacea Management - Why It Matters: Nursing Implications and Patient Education. Dermatology Nursing, 20(6), 10-14.</Typography>
                    </div>
                </>
 
    )
};

export default ProviderProfiles;


        