import React from 'react';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import ProviderProfiles from '../components/ProviderProfiles';

const Providers = () => {
    return (
        <Layout>
            <Heading>Providers</Heading>
            <ProviderProfiles />
        </Layout>
    )
}

export default Providers;

